export const PARTNER_CONSTENT = {
  initialField: [
    {
      id: 1,
      label: 'Partner Name',
      fieldName: 'serviceName',
      fieldType: 'TEXT',
      dataType: 'STRING',
      placeholder: 'Partner Name',
      isValid: true,
      errorMessage: '',
      value: '',
      validations: { required: true, maxLength: 100, alphaNumericWithSpaceAndUnderscore: true },
    },
    {
      id: 2,
      label: 'Partner Alias',
      fieldName: 'serviceAlias',
      fieldType: 'TEXT',
      dataType: 'STRING',
      placeholder: 'Partner Alias',
      errorMessage: '',
      value: '',
      validations: {
        required: true,
        maxLength: 100,
        uppercaseAlphabaticWithUnderscore: true,
      },
      isValid: true,
    },
    {
      id: 3,
      label: 'Contract Start Date',
      fieldName: 'contractStartDate',
      fieldType: 'DATETIME',
      dataType: 'DATE',
      placeholder: 'Contract Start Date',
      isValid: true,
      errorMessage: '',
      value: null,
      dependencyIndex: 3,
      validations: { maxCompareDate: true },
    },
    {
      id: 4,
      label: 'Contract End Date',
      fieldName: 'contractEndDate',
      fieldType: 'DATETIME',
      dataType: 'DATE',
      placeholder: 'Contract End Date',
      isValid: true,
      errorMessage: '',
      value: null,
      dependencyIndex: 2,
      validations: { minCompareDate: true },
    },
    {
      id: 5,
      label: 'Email ID',
      fieldName: 'contactEmail',
      fieldType: 'TEXT',
      dataType: 'STRING',
      placeholder: 'Email ID',
      isValid: true,
      errorMessage: '',
      value: '',
      validations: { required: true },
    },
    {
      id: 6,
      label: 'Content Number',
      fieldName: 'contactPhone',
      fieldType: 'NUMBER',
      dataType: 'NUMBER',
      placeholder: 'Content Number',
      isValid: true,
      errorMessage: '',
      value: null,
      validations: {},
    },
    {
      id: 7,
      label: 'Status',
      fieldName: 'status',
      fieldType: 'SELECT',
      dataType: 'STRING',
      placeholder: 'Status',
      labelKey: 'value',
      isValid: true,
      isMultiple: false,
      errorMessage: '',
      value: { value: 'Active', key: 'ACTIVE' },
      options: [
        { value: 'Active', key: 'ACTIVE' },
        { value: 'In Active', key: 'INACTIVE' },
      ],
      validations: { required: true },
    },
    {
      id: 8,
      label: 'Allowed Asset Type',
      fieldName: 'allowedAssetTypes',
      fieldType: 'SELECT',
      dataType: 'STRING',
      placeholder: 'Allowed Asset Type',
      labelKey: 'assetType',
      isValid: true,
      isMultiple: true,
      errorMessage: '',
      value: [],
      options: [],
      validations: {},
    },
    {
      id: 9,
      label: 'Allowed Masters',
      fieldName: 'allowedMasters',
      fieldType: 'SELECT',
      dataType: 'STRING',
      placeholder: 'Allowed Masters',
      labelKey: 'masterKey',
      isValid: true,
      isMultiple: true,
      errorMessage: '',
      value: [],
      options: [],
      validations: {},
    },
  ],
  viewResponse: {
    _id: '666dbfbd16d40c4c5168faab',
    serviceName: 'WWE',
    serviceAlias: 'WWE',
    status: 'ACTIVE',
    contractStartdate: null,
    contractEnddate: null,
    contactPhone: 8759684348,
    contactEmail: 'tanmoy.sen@kellton.com',
    allowedMasters: ['Affiliate Partner'],
    allowedAssetTypes: ['AbhiTestBundle'],
  },
  viewResponseWithEmptyMaster: {
    _id: '666dbfbd16d40c4c5168faab',
    serviceName: 'WWE',
    serviceAlias: 'Superstars',
    status: 'ACTIVE',
    contractStartdate: '2024-07-17T15:52:35.161Z',
    contractEnddate: '2024-07-17T15:52:35.161Z',
    contactPhone: 8759684348,
    contactEmail: 'tanmoy.sen@kellton.com',
    allowedMasters: [],
    allowedAssetTypes: [],
  },
  getPartnersListRespronse: {
    message: 'Success',
    responseCode: 200,
    data: {
      count: 2,
      records: [
        {
          _id: '6697bb280db2bccdbf4f71a1',
          serviceName: ' WWE',
          serviceAlias: 'FIFA',
          contractStartdate: '01/01/2023, 05:30:00',
          contractEnddate: '01/01/2023, 05:30:00',
          contactEmail: 'tanmoy@gmail.com',
          contactPhone: 8759684348,
          status: 'Active',
        },
        {
          _id: '6697bb280db2bccdbf4f71a1',
          serviceName: ' FIFA',
          serviceAlias: 'WWE',
          contractStartdate: '01/01/2023, 05:30:00',
          contractEnddate: '01/01/2023, 05:30:00',
          contactEmail: 'tanmoy@gmail.com',
          contactPhone: 8759684348,
          status: 'Active',
        },
      ],
    },
    error: null,
  },
  PARTNER_VIEW_RESPONSE_MOCK: {
    message: 'Partner updated successfully!',
    responseCode: 200,
    data: {
      _id: '669e0fc6575c1fcf50be2a7d',
      allowedMasters: ['affiliatePartner'],
      allowedAssetTypes: ['AbhiTestBundle'],
      serviceName: 'partner 3',
      serviceAlias: 'p3',
      contractStartDate: '2024-07-20T18:30:00.000Z',
      contractEndDate: '2024-07-30T18:30:00.000Z',
      contactEmail: 'tanm@gmail.com',
      contactPhone: '5869325478',
      createdBy: '668648e81fed739855d18a8a',
      updatedBy: '668648e81fed739855d18a8a',
      status: 'INACTIVE',
      createdAt: '2024-07-22T07:52:38.644Z',
      updatedAt: '2024-07-22T08:50:22.490Z',
      __v: 0,
    },
    error: null,
  },
  PARTNER_VIEW_RESPONSE_MOCK_FOR_SUCCESS_STATUS: {
    _id: '669e0fc6575c1fcf50be2a7d',
    serviceName: 'partner 3',
    serviceAlias: 'p3',
    contractStartDate: '2024-07-20T18:30:00.000Z',
    contractEndDate: '2024-07-30T18:30:00.000Z',
    contactEmail: 'tanm@gmail.com',
    contactPhone: '5869325478',
    createdBy: '668648e81fed739855d18a8a',
    updatedBy: '668648e81fed739855d18a8a',
    status: 'ACTIVE',
    Verificationstatus: 'SUCCESS',
    createdAt: '2024-07-22T07:52:38.644Z',
    updatedAt: '2024-07-22T08:50:22.490Z',
    __v: 0,
  },
  PARTNER_VIEW_RESPONSE_MOCK_FOR_INACTIVE: {
    message: 'Partner updated successfully!',
    responseCode: 200,
    data: {
      _id: '669e0fc6575c1fcf50be2a7d',
      serviceName: 'partner 3',
      serviceAlias: 'p3',
      contractStartDate: '2024-07-20T18:30:00.000Z',
      contractEndDate: '2024-07-30T18:30:00.000Z',
      contactEmail: 'tanm@gmail.com',
      contactPhone: '5869325478',
      createdBy: '668648e81fed739855d18a8a',
      updatedBy: '668648e81fed739855d18a8a',
      status: 'ACTIVE',
      Verificationstatus: 'PENDING',
      createdAt: '2024-07-22T07:52:38.644Z',
      updatedAt: '2024-07-22T08:50:22.490Z',
      __v: 0,
    },
    error: null,
  },
  PARTNER_VIEW_RESPONSE_MOCK_FAIL: {
    message: 'Some error message!',
    responseCode: 422,
    data: null,
    error: null,
  },
  PARTNER_DETAILS_API_MOCK: {
    message: 'Partner updated successfully!',
    responseCode: 200,
    data: {
      _id: '669e0fc6575c1fcf50be2a7d',
      serviceName: 'partner 3',
      serviceAlias: 'p3',
      contractStartDate: '2024-07-20T18:30:00.000Z',
      contractEndDate: '2024-07-30T18:30:00.000Z',
      contactEmail: 'tanm@gmail.com',
      contactPhone: '5869325478',
      createdBy: '668648e81fed739855d18a8a',
      updatedBy: '668648e81fed739855d18a8a',
      allowedMasters: ['Affiliate Partner'],
      allowedAssetTypes: ['CELEBRITY_PAGE'],
      status: 'INACTIVE',
      createdAt: '2024-07-22T07:52:38.644Z',
      updatedAt: '2024-07-22T08:50:22.490Z',
      __v: 0,
    },
    error: null,
  },
  PARTNER_VIEW_MAPPING_DATA: [
    {
      label: 'Partner Name',
      value: 'partner 1',
      isTitle: true,
    },
    {
      label: 'Partner Alias',
      value: 'alias',
      isTitle: false,
    },
    {
      label: 'Contact Email',
      value: 'ddsdsd@gmail.com',
      isTitle: false,
    },
    {
      label: 'Contact Phone',
      value: '',
      isTitle: false,
    },
    {
      label: 'Status',
      value: 'ACTIVE',
      isTitle: false,
    },
    {
      label: 'Contract Start Date',
      value: '01/07/2024, 12:00:00 ',
      isTitle: false,
    },
    {
      label: 'Contract End Date',
      value: '31/07/2024, 12:00:00 ',
      isTitle: false,
    },
  ],
  AVOID_KEY_FOR_PHONE: ['E', 'e', '+', '-', '.'],
  LIST_OPTIONS: {
    masterList: [
      {
        value: 'affiliatePartner',
        key: 'affiliatePartner',
        masterKey: 'Affiliate Partner',
      },
    ],
    assetTypeList: [
      {
        _id: '667547e7226a4ae20dc42422',
        assetType: 'AbhiTestBundle',
        assetCategory: 'BUNDLE',
        contentCategory: 'ORIGINALS',
        value: 'AbhiTestBundle',
      },
    ],
  },
  errorMessageForAllowed: 'Please add Either Allowed Asset Type or Allowed Masters',
}
