import { IconButton } from '@mui/material'
import {
  checkIsPartnerContentItemFailed,
  checkIsPartnerParentsFailed,
} from '../../../../Helpers/CommonListingHelper'
import { setcontentIngestionRetrigger } from '../../../../Store/Slice/reports.slice'
import { AccordianOpenIcon } from '../../../Icons'
import ReTriggerIcon from '../../../Icons/ReTrigger'
import ChipButton from '../../ChipButton'
import { useDialog } from '../../Dialog/DialogContext'

const commonListingReportActionBtn = (dispatch: any, item: any, label: string, rowsData: any) => {
  const allowedRetrigger = ['WWE']
  const regex = new RegExp(`^(${allowedRetrigger.join('|')})`)
  const isRetriggerEnable =
    regex.test(item?.partnerContentId) &&
    (checkIsPartnerContentItemFailed(item) || checkIsPartnerParentsFailed(item, rowsData))
  return (
    <div className='d-flex align-items-center'>
      <div
        onClick={(e: any) => {
          e.stopPropagation()
          e.preventDefault()
          if (!isRetriggerEnable) {
            return
          }
          if (label === 'Re-trigger') {
            // if (label === 'Details') {
            //   dispatch(getRowDetailById(item?._id) as any)
            // }
            dispatch(
              setcontentIngestionRetrigger({
                show: true,
                data: item || {},
              }) as any,
            )
          }
        }}
        className={`${isRetriggerEnable ? 'cursor-pointer' : 'retrigger-disabled'}`}
        data-testid='edit__chip-btn'
        style={{
          width: 'fit-content',
          opacity: isRetriggerEnable ? 1 : 0.5,
        }}
      >
        <ChipButton
          type='primary'
          style={{ gap: 0, padding: '14px 8px' }}
          label={''}
          Icon={ReTriggerIcon as any}
        />
      </div>
      <CommonListingReportActionError item={item} />
    </div>
  )
}

const CommonListingReportActionError = ({ item }: any) => {
  const showDialog = useDialog()

  const showErrorDialog = (data: any) => {
    showDialog('confirm', {
      width: '500px',
      icon: 'DeleteRoleWarIcon',
      title: 'Error Details',
      description: data?.failureReason,
      btn1Label: 'Close',
      onClose: () => undefined,
    })
  }

  return (
    <div className='d-flex align-items-center'>
      <IconButton
        data-testid='expand_row'
        size='small'
        style={{
          opacity: item?.failureReason ? 1 : 0.5,
          cursor: item?.failureReason ? 'pointer' : 'default',
          backgroundColor: 'transparent',
        }}
        onClick={(e: any) => {
          e.stopPropagation()
          e.preventDefault()
          if (item?.failureReason) {
            showErrorDialog(item)
          }
        }}
      >
        <AccordianOpenIcon />
      </IconButton>
    </div>
  )
}

export default commonListingReportActionBtn
