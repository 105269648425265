import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import manageContent from '../../../src/Constants/Constant/Schema/managContent.json'
import AdvancedSearchForm from '../../Components/GlobalSearch/AdvancedSearch/AdvanceSearchForm'
import AdvancedSearchList from '../../Components/GlobalSearch/AdvancedSearch/AdvancedSearchList'
import { BackButton, Breadcrumbs } from '../../Components/Shared'
import { BREADCRUMS, PAGES_ROUTES } from '../../Constants'
import {
  GLOBAL_SEARCH,
  HOME_PAGE,
  MASTERS,
  SYSTEM_CONFIG_APIS,
} from '../../Constants/ApiDefinations'
import { getSavedFilter } from '../../Helpers/CommonListingHelper'
import { PERMISSIONS, PERMISSION_TYPE, checkPermission } from '../../Helpers/Permission'
import { deepClone, removeDuplicates } from '../../Helpers/Util'
import { BreadcrumsModel, TypeObject } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import { setLoader } from '../../Store/Slice/loader.slice'

/**
 * Advanced Search Page Component
 *
 * @returns {React.JSX.Element} React JSX element representing the Advanced Search Page Ccomponent.
 * */

const AdvancedSearch = () => {
  const [contentCategories, setContentCategories] = useState<TypeObject[]>([])
  const [assetCategory, setAssetCategory] = useState<TypeObject[]>([])
  const [platforms, setPlatforms] = useState<TypeObject[]>([])
  const [countries, setCountries] = useState<TypeObject[]>([])
  const [attributes, setAttributes] = useState<TypeObject[]>([])
  const [assetTypes, setAssetTypes] = useState<TypeObject[]>([])
  const [searchPayload, setSearchPayload] = useState<any>(null)
  const [searchData, setSearchData] = useState<any>([])
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const searchKey = searchParams.get('search')
  const advanceSearch = searchParams.get('advanceSearch')
  const access = checkPermission(PERMISSIONS.MANAGE_CONTENT, [PERMISSION_TYPE.CAN_ACCESS])
  const [mounted, setMounted] = useState(false)
  const [savedFiltersData, setSavedFilterData] = useState<any>({})

  const [formData, setFormData] = useState<TypeObject>({
    title: null,
    contentCategory: [],
    assetCategory: [],
    assetType: [],
    state: [],
    countries: [],
    activePlatform: [],
    availabilityStartTime: { filter: 'equals', value: '' },
    availabilityEndTime: { filter: 'equals', value: '' },
    startsIn: { filter: 'nextDay', value: '' },
    filterIn: { filter: true, value: '' },
    expiresIn: { filter: 'nextDay', value: '' },
    attributeFilters: [],
  })

  useEffect(() => {
    if (!access) {
      navigate(PAGES_ROUTES.AccessDenied)
    }
  }, [access])

  useEffect(() => {
    if (advanceSearch) {
      getDataSystemConfig()
      getDataMasters()
      getAssetTypeDefinition()
    }
    getAttributesList()
  }, [advanceSearch])

  const getPinnedSearchData = async () => {
    const requestedData = HOME_PAGE.GET_PINNED_SEAECH(location?.state?.id)
    const result = await mainApiService(requestedData as any)
    if (result?.data) {
      onSubmit({ ...result.data, page: 1, limit: 10 })
    }
  }

  useEffect(() => {
    if (location?.state?.id) {
      getPinnedSearchData()
    } else {
      if (searchKey && !searchParams.get('filter')) {
        onSubmit({
          titleOrId: searchKey,
          contentFilters: [],
          attributeFilters: [],
          sort: [],
          page: 1,
          limit: 10,
        })
      } else {
        const savedFilters = getSavedFilter('/advanced-search')
        if (savedFilters) setSavedFilterData(deepClone({ ...savedFilters }))
        if (savedFilters?.filterData && searchParams.get('filter')) {
          onSubmit(savedFilters?.filterData, true)
        }

        if (Object.keys(savedFilters?.formData ?? [])?.length && searchParams.get('filter')) {
          setFormData(
            deepClone({
              ...savedFilters.formData,
            }),
          )
        }
      }
    }
  }, [location?.state?.id, searchKey])

  /**
   * To get the data of system configuration asset category and content category
   * @function getDataSystemConfig
   * @returns {void}
   */
  const getDataSystemConfig = async () => {
    const reqData = SYSTEM_CONFIG_APIS.LIST_MASTERS('CONTENT_CATEGORY,ASSET_CATEGORY')
    const result = await mainApiService(reqData)
    if (result?.data?.length) {
      const { data = [] } = result
      setContentCategories(
        data?.find((config: TypeObject) => config?.configKey === 'CONTENT_CATEGORY')?.configValue ??
          [],
      )
      setAssetCategory(
        data?.find((config: TypeObject) => config?.configKey === 'ASSET_CATEGORY')?.configValue ??
          [],
      )
    }
  }

  /**
   * To get the data of masters downstreamPlatforms and countries
   * @function getDataMasters
   * @returns {void}
   */
  const getDataMasters = async () => {
    const reqDataPlatforms = MASTERS.LIST_MASTERS('downstreamPlatforms')
    const resultPlatforms = await mainApiService(reqDataPlatforms)
    if (resultPlatforms?.data?.records?.length) {
      setPlatforms(resultPlatforms?.data?.records)
    }

    const reqDataCountries = MASTERS.LIST_MASTERS('countries')
    const resultCountries = await mainApiService(reqDataCountries)
    if (resultCountries?.data?.records?.length) {
      setCountries(resultCountries?.data?.records)
    }
  }

  /**
   * To get the attribute list
   * @function getAttributesList
   * @returns {void}
   */
  const getAttributesList = async () => {
    const reqData = GLOBAL_SEARCH.ATTRIBUTE_LIST()
    const result: any = await mainApiService(reqData)
    const manageContentFields = Object.keys(manageContent)
    if (result?.data?.length) {
      const attrList = result.data.filter((item: any) =>
        manageContentFields.includes(item.fieldName),
      )
      setAttributes(attrList)
    }
  }

  /**
   * To get the asset type definition list
   * @function getAssetTypeDefinition
   * @returns {void}
   */
  const getAssetTypeDefinition = async () => {
    const reqData = GLOBAL_SEARCH.ASSET_DEFINITION_LIST()
    const result = await mainApiService(reqData)
    if (result?.data?.length) {
      const parsedData = result.data.map((item: { value: string; assetType: string }) => ({
        ...item,
        value: item.assetType,
      }))
      const uniqueAssetType = removeDuplicates(parsedData, 'assetType')
      setAssetTypes(uniqueAssetType)
    }
  }

  const setSearchParamData = () => {
    const updatedSearchParams = new URLSearchParams(searchParams)

    Object.entries({ filter: '1' }).forEach(([key, value]) => {
      if (value) {
        updatedSearchParams.set(key, value)
      } else {
        updatedSearchParams.delete(key)
      }
    })
    navigate(`?${updatedSearchParams.toString()}`)
  }

  /**
   * To submit the form on trigger could be form input or table filter change method
   * @function onSubmit
   * @param {object} data - payload data to be send in API coming from form and list.
   * @returns {void}
   */
  const onSubmit = async (data: TypeObject, from?: any) => {
    setMounted(from)
    dispatch(setLoader(true))
    setSearchPayload(data)

    const reqPayload = GLOBAL_SEARCH.ADVANCE_SEARCH(data)
    const result: any = await mainApiService(reqPayload)

    if (result?.error) {
      dispatch(showAlert([true, result?.error?.message, 'error']))
      dispatch(setLoader(false))
      return
    }

    setSearchData(result?.data)
    setSearchParamData()

    const id = document.getElementById('advanced_search_list')
    if (id?.scrollIntoView) {
      id.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
    dispatch(setLoader(false))
  }

  return (
    <>
      <div className='pt-1 pl-2 pr-2 advancedSearchWrapper'>
        <div className=''>
          <Breadcrumbs breadcrums={BREADCRUMS.ADVANCED_SEARCH_BREADCRUMS() as BreadcrumsModel[]} />
        </div>
        <div className='mt-1'>
          <BackButton
            label='Advance Search'
            className='col73'
            handleOnClick={() => {
              navigate('/home')
            }}
          />
        </div>
        {advanceSearch && attributes?.length ? (
          <div className='pb-2 pt-1'>
            <AdvancedSearchForm
              contentCategories={contentCategories}
              attributes={attributes}
              assetCategory={assetCategory}
              platforms={platforms}
              countries={countries}
              assetTypes={assetTypes}
              submitForm={onSubmit}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {searchPayload && attributes?.length ? (
        <AdvancedSearchList
          attributes={attributes}
          formData={formData}
          searchPayload={searchPayload as TypeObject[]}
          searchData={searchData}
          submitForm={onSubmit}
          mounted={mounted}
          savedFilters={savedFiltersData}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default AdvancedSearch
