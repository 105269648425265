import React from 'react'

export const BlitzLogo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='71' height='32' viewBox='0 0 71 32' fill='none'>
      <path
        d='M0.0438803 31.4412L3.62891 5.93237H8.98708L7.63919 15.5231H7.80271C8.10985 15.0083 8.52297 14.4851 9.04208 13.9537C9.57073 13.414 10.2207 12.9656 10.992 12.6085C11.7729 12.2431 12.7042 12.0605 13.7859 12.0605C15.1946 12.0605 16.443 12.4258 17.531 13.1565C18.6201 13.879 19.4184 14.9709 19.9258 16.4323C20.4343 17.8855 20.5345 19.7081 20.2264 21.9003C19.9265 24.0343 19.3253 25.8362 18.4227 27.3059C17.5298 28.7674 16.4348 29.8759 15.1379 30.6316C13.8506 31.3789 12.4732 31.7525 11.0058 31.7525C9.96598 31.7525 9.10526 31.5823 8.42359 31.2419C7.75031 30.9014 7.21925 30.4738 6.83042 29.959C6.44275 29.4358 6.16467 28.9085 5.99619 28.3771H5.75721L5.32659 31.4412H0.0438803ZM6.63324 21.8754C6.47336 23.013 6.49322 24.0052 6.69283 24.8522C6.89243 25.6992 7.26084 26.3593 7.79806 26.8326C8.33645 27.2976 9.03748 27.5301 9.90116 27.5301C10.7732 27.5301 11.5444 27.2935 12.2146 26.8202C12.8861 26.3386 13.4364 25.6743 13.8657 24.8273C14.3046 23.972 14.6022 22.9881 14.7585 21.8754C14.9138 20.771 14.8952 19.7995 14.7028 18.9608C14.5104 18.1221 14.1456 17.4661 13.6084 16.9928C13.0711 16.5195 12.3623 16.2829 11.4819 16.2829C10.6098 16.2829 9.8398 16.5112 9.17188 16.9679C8.51234 17.4246 7.96432 18.0723 7.52781 18.911C7.09131 19.7496 6.79312 20.7378 6.63324 21.8754Z'
        fill='white'
      />
      <path d='M30.3414 5.93237L26.7564 31.4412H21.3982L24.9832 5.93237H30.3414Z' fill='white' />
      <path d='M29.986 31.4412L32.6748 12.3096H38.0329L35.3442 31.4412H29.986Z' fill='white' />
      <path
        d='M51.5387 12.3096L50.9785 16.2953H39.344L39.9042 12.3096H51.5387ZM43.1897 7.72596H48.5479L46.0411 25.5622C45.9723 26.0521 45.9941 26.4341 46.1065 26.7081C46.2201 26.9738 46.4035 27.1606 46.6566 27.2686C46.9182 27.3765 47.2292 27.4305 47.5898 27.4305C47.8413 27.4305 48.0958 27.4097 48.3532 27.3682C48.6118 27.3184 48.8099 27.281 48.9475 27.2561L49.2354 31.2045C48.9554 31.2875 48.5646 31.383 48.0631 31.491C47.5604 31.6072 46.9593 31.6778 46.2598 31.7027C44.9615 31.7525 43.8534 31.5823 42.9356 31.192C42.0261 30.8018 41.3608 30.1956 40.9397 29.3736C40.5186 28.5515 40.4003 27.5135 40.5849 26.2597L43.1897 7.72596Z'
        fill='white'
      />
      <path
        d='M51.1771 31.4412L51.6217 28.2775L62.71 16.6814L62.7292 16.5444H53.5977L54.1929 12.3096H69.7768L69.292 15.7597L58.8225 27.0693L58.8033 27.2063H68.0103L67.4151 31.4412H51.1771Z'
        fill='white'
      />
      <path
        d='M33.6484 5.77854L38.5419 0.247314L37.0739 4.90519L39.8468 5.19631L34.6271 10.8731L36.2583 6.36078L33.6484 5.77854Z'
        fill='white'
      />
    </svg>
  )
}
type colortype = {
  color?: string
}
export const HomeIcon: React.FC<colortype> = ({ color = '#3E3F40' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.91998 4.03463L9.9202 4.03481L9.91998 4.03463ZM10 4.10131L16.125 9.20548V16.125H12.4687V12.3438C12.4687 11.5844 11.8531 10.9688 11.0937 10.9688H8.90625C8.14686 10.9688 7.53125 11.5844 7.53125 12.3438V16.125H3.875V9.20548L10 4.10131ZM16.17 9.24296C16.1699 9.24289 16.1698 9.24282 16.1697 9.24274L16.17 9.24296ZM9.11975 2.88229C9.62966 2.45737 10.3703 2.45737 10.8803 2.88229L17.1303 8.09063C17.4437 8.35187 17.625 8.73886 17.625 9.14693V16.25C17.625 17.0094 17.0094 17.625 16.25 17.625H12.3437C11.5844 17.625 10.9687 17.0094 10.9687 16.25V12.4688H9.03125V16.25C9.03125 17.0094 8.41564 17.625 7.65625 17.625H3.75C2.99061 17.625 2.375 17.0094 2.375 16.25V9.14693C2.375 8.73886 2.55626 8.35187 2.86975 8.09063L9.11975 2.88229Z'
        fill={color}
      />
    </svg>
  )
}

export const ManageContentIcon: React.FC<colortype> = ({ color = '#3E3F40' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M7.45257 3.19644C7.45257 2.81181 7.76438 2.5 8.14901 2.5H16.8928C17.9185 2.5 18.75 3.33149 18.75 4.35718V11.97C18.75 12.3547 18.4382 12.6665 18.0536 12.6665C17.6689 12.6665 17.3571 12.3547 17.3571 11.97V4.35718C17.3571 4.10076 17.1492 3.89289 16.8928 3.89289H8.14901C7.76438 3.89289 7.45257 3.58108 7.45257 3.19644Z'
        fill={color}
      />
      <path
        d='M11.1504 11.1977C11.4914 10.9676 11.4914 10.4657 11.1504 10.2356L9.05405 8.8207C8.66859 8.56055 8.14901 8.83673 8.14901 9.30176V12.1315C8.14901 12.5965 8.66859 12.8727 9.05405 12.6125L11.1504 11.1977Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5 6.86392C2.5 5.83823 3.33149 5.00674 4.35718 5.00674H14.4164C15.4421 5.00674 16.2736 5.83823 16.2736 6.86392V14.5694C16.2736 15.5951 15.4421 16.4266 14.4164 16.4266H4.35718C3.33149 16.4266 2.5 15.5951 2.5 14.5694V6.86392ZM4.35718 6.39963C4.10076 6.39963 3.89289 6.6075 3.89289 6.86392V14.5694C3.89289 14.8258 4.10076 15.0337 4.35718 15.0337H14.4164C14.6728 15.0337 14.8807 14.8258 14.8807 14.5694V6.86392C14.8807 6.6075 14.6728 6.39963 14.4164 6.39963H4.35718Z'
        fill={color}
      />
    </svg>
  )
}

export const RoleManagementIcon: React.FC<colortype> = ({ color = '#3E3F40' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      data-testid='role-management-icon-svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.54499 3.47339C7.4754 3.47339 6.60833 4.34046 6.60833 5.41005C6.60833 6.47964 7.4754 7.34671 8.54499 7.34671C9.61458 7.34671 10.4817 6.47964 10.4817 5.41005C10.4817 4.34046 9.61458 3.47339 8.54499 3.47339ZM5.10833 5.41005C5.10833 3.51204 6.64698 1.97339 8.54499 1.97339C10.443 1.97339 11.9817 3.51204 11.9817 5.41005C11.9817 7.03618 10.8523 8.39852 9.33507 8.75546C10.2689 8.89164 11.1405 9.23545 11.9077 9.73994C12.2538 9.96751 12.3499 10.4326 12.1224 10.7787C11.8948 11.1248 11.4297 11.2209 11.0836 10.9933C10.3049 10.4813 9.39821 10.19 8.43085 10.19C5.62734 10.19 3.25 12.6809 3.25 15.888C3.25 16.3641 3.63593 16.75 4.112 16.75H9.77418C10.1884 16.75 10.5242 17.0858 10.5242 17.5C10.5242 17.9143 10.1884 18.25 9.77418 18.25H4.112C2.8075 18.25 1.75 17.1925 1.75 15.888C1.75 12.2408 4.29564 9.14225 7.67372 8.73531C6.19761 8.34961 5.10833 7.00704 5.10833 5.41005ZM14 11.4059C14.4254 11.1603 14.9496 11.1603 15.375 11.4059L17.1857 12.4513C17.6111 12.697 17.8732 13.1509 17.8732 13.6421V15.7329C17.8732 16.2242 17.6111 16.6781 17.1857 16.9237L15.375 17.9691C14.9496 18.2148 14.4254 18.2148 14 17.9691L12.1893 16.9237C11.7639 16.6781 11.5018 16.2242 11.5018 15.7329V13.6421C11.5018 13.1509 11.7639 12.697 12.1893 12.4513L14 11.4059ZM13.0018 13.7143V15.6608L14.6875 16.634L16.3732 15.6608V13.7143L14.6875 12.7411L13.0018 13.7143Z'
        fill={color}
      />
    </svg>
  )
}
export const UserManagementIcon: React.FC<colortype> = ({ color = '#3E3F40' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_1628_4437)'>
        <path
          d='M7.49984 9.79163C6.92484 9.79163 6.45817 10.2583 6.45817 10.8333C6.45817 11.4083 6.92484 11.875 7.49984 11.875C8.07484 11.875 8.5415 11.4083 8.5415 10.8333C8.5415 10.2583 8.07484 9.79163 7.49984 9.79163ZM12.4998 9.79163C11.9248 9.79163 11.4582 10.2583 11.4582 10.8333C11.4582 11.4083 11.9248 11.875 12.4998 11.875C13.0748 11.875 13.5415 11.4083 13.5415 10.8333C13.5415 10.2583 13.0748 9.79163 12.4998 9.79163ZM9.99984 1.66663C5.39984 1.66663 1.6665 5.39996 1.6665 9.99996C1.6665 14.6 5.39984 18.3333 9.99984 18.3333C14.5998 18.3333 18.3332 14.6 18.3332 9.99996C18.3332 5.39996 14.5998 1.66663 9.99984 1.66663ZM9.99984 16.6666C6.32484 16.6666 3.33317 13.675 3.33317 9.99996C3.33317 9.75829 3.34984 9.51663 3.37484 9.28329C5.3415 8.40829 6.89984 6.79996 7.7165 4.80829C9.22484 6.94163 11.7082 8.33329 14.5165 8.33329C15.1665 8.33329 15.7915 8.25829 16.3915 8.11663C16.5665 8.70829 16.6665 9.34163 16.6665 9.99996C16.6665 13.675 13.6748 16.6666 9.99984 16.6666Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1628_4437'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const MetaMasterIcon: React.FC<colortype> = ({ color = '#3E3F40' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M18.0303 16.7803C17.7374 17.0732 17.2625 17.0732 16.9696 16.7803L15.75 15.5607V16.0594C15.75 16.4736 15.4142 16.8094 15 16.8094C14.5858 16.8094 14.25 16.4736 14.25 16.0594V13.75C14.25 13.3358 14.5858 13 15 13H17.3094C17.7236 13 18.0594 13.3358 18.0594 13.75C18.0594 14.1642 17.7236 14.5 17.3094 14.5H16.8106L18.0303 15.7197C18.3232 16.0126 18.3232 16.4874 18.0303 16.7803Z'
        fill={color}
      />
      <path
        d='M1.75 5C1.75 3.89543 2.64543 3 3.75 3H16.25C17.3546 3 18.25 3.89543 18.25 5V10.7812C18.25 11.1955 17.9142 11.5312 17.5 11.5312C17.0858 11.5312 16.75 11.1955 16.75 10.7812V5C16.75 4.72386 16.5261 4.5 16.25 4.5H3.75C3.47386 4.5 3.25 4.72386 3.25 5V15C3.25 15.2761 3.47386 15.5 3.75 15.5H11.875C12.2892 15.5 12.625 15.8358 12.625 16.25C12.625 16.6642 12.2892 17 11.875 17H3.75C2.64543 17 1.75 16.1046 1.75 15V5Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.97043 7.54071C7.92191 7.50169 7.86897 7.46911 7.81302 7.44345C7.71029 7.3962 7.60203 7.37413 7.49542 7.37486C7.39383 7.3754 7.29092 7.39666 7.1929 7.44073C7.1271 7.47021 7.06535 7.50922 7.00994 7.55699C6.93112 7.62488 6.86871 7.70741 6.82479 7.79833L4.95416 11.5396C4.76892 11.9101 4.91909 12.3606 5.28957 12.5458C5.66005 12.7311 6.11056 12.5809 6.2958 12.2104L6.40887 11.9843C6.45844 11.9946 6.50981 12 6.56244 12H8.59892L8.70413 12.2104C8.88937 12.5809 9.33987 12.7311 9.71036 12.5458C10.0808 12.3606 10.231 11.9101 10.0458 11.5396L8.1753 7.79866C8.12781 7.70022 8.05864 7.61159 7.97043 7.54071ZM7.151 10.5L7.49996 9.8021L7.84892 10.5H7.151Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4999 12.625C13.9497 12.625 15.1249 11.4498 15.1249 10C15.1249 8.55027 13.9497 7.37502 12.4999 7.37502H11.6187C11.1734 7.37502 10.8124 7.73599 10.8124 8.18127V11.8188C10.8124 12.264 11.1734 12.625 11.6187 12.625H12.4999ZM12.4999 8.87502C13.1213 8.87502 13.6249 9.3787 13.6249 10C13.6249 10.6213 13.1213 11.125 12.4999 11.125H12.3124V8.87502H12.4999Z'
        fill={color}
      />
    </svg>
  )
}
export const AuditLogsIcon: React.FC<colortype> = ({ color = '#3E3F40' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.875 3.23665C1.875 2.48463 2.48463 1.875 3.23665 1.875H11.9017C12.6538 1.875 13.2634 2.48463 13.2634 3.23665V6.95026C13.2634 7.36045 12.9309 7.69298 12.5207 7.69298C12.1105 7.69298 11.7779 7.36045 11.7779 6.95026V3.36044H3.36044V15.4915H8.18813C8.59832 15.4915 8.93085 15.8241 8.93085 16.2343C8.93085 16.6445 8.59832 16.977 8.18813 16.977H3.23665C2.48463 16.977 1.875 16.3674 1.875 15.6153V3.23665Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.96967 6.33132C4.96967 5.92113 5.3022 5.5886 5.71239 5.5886H9.42599C9.83619 5.5886 10.1687 5.92113 10.1687 6.33132C10.1687 6.74152 9.83619 7.07404 9.42599 7.07404H5.71239C5.3022 7.07404 4.96967 6.74152 4.96967 6.33132Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.96967 9.42599C4.96967 9.0158 5.3022 8.68327 5.71239 8.68327H6.95026C7.36045 8.68327 7.69298 9.0158 7.69298 9.42599C7.69298 9.83619 7.36045 10.1687 6.95026 10.1687H5.71239C5.3022 10.1687 4.96967 9.83619 4.96967 9.42599Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.7212 10.9367C13.1568 11.2574 12.0527 11.8112 10.8527 11.9781C10.9762 12.9516 11.2311 13.6811 11.6633 14.3515C12.1078 15.0409 12.7649 15.7071 13.7546 16.5174C14.6577 15.7654 15.2785 15.1352 15.7216 14.4552C16.1563 13.7879 16.4435 13.0357 16.6217 12.0099C15.3251 11.8773 14.2705 11.2943 13.7291 10.9388L13.7266 10.9378L13.7212 10.9367ZM12.9927 9.64223C13.4855 9.36175 14.0899 9.39868 14.5444 9.69705C14.9965 9.99392 15.8289 10.4424 16.7998 10.5348C17.5009 10.6016 18.2545 11.2309 18.1061 12.14C17.9037 13.3804 17.5516 14.3674 16.9662 15.266C16.3879 16.1536 15.6074 16.915 14.6126 17.7357C14.1178 18.1438 13.4035 18.1457 12.9064 17.7424C11.8233 16.8636 10.9986 16.0617 10.4149 15.1564C9.82127 14.2358 9.50351 13.2505 9.36437 12.0433C9.26514 11.1823 9.96478 10.5975 10.6227 10.5102C11.5544 10.3867 12.4783 9.93497 12.9927 9.64223Z'
        fill={color}
      />
    </svg>
  )
}
export const ReportIcon: React.FC<colortype> = ({ color = '#3E3F40' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.125 6.875C6.125 6.11561 6.74061 5.5 7.5 5.5H16.25C17.0094 5.5 17.625 6.11561 17.625 6.875V16.875C17.625 17.6344 17.0094 18.25 16.25 18.25H7.5C6.74061 18.25 6.125 17.6344 6.125 16.875V6.875ZM7.625 7V16.75H16.125V7H7.625Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.25 9.375C9.25 8.96079 9.58579 8.625 10 8.625H13.75C14.1642 8.625 14.5 8.96079 14.5 9.375C14.5 9.78921 14.1642 10.125 13.75 10.125H10C9.58579 10.125 9.25 9.78921 9.25 9.375Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.25 11.875C9.25 11.4608 9.58579 11.125 10 11.125H13.75C14.1642 11.125 14.5 11.4608 14.5 11.875C14.5 12.2892 14.1642 12.625 13.75 12.625H10C9.58579 12.625 9.25 12.2892 9.25 11.875Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.25 14.375C9.25 13.9608 9.58579 13.625 10 13.625H13.75C14.1642 13.625 14.5 13.9608 14.5 14.375C14.5 14.7892 14.1642 15.125 13.75 15.125H10C9.58579 15.125 9.25 14.7892 9.25 14.375Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.375 3.75C2.375 2.64543 3.27043 1.75 4.375 1.75H11.875C12.2892 1.75 12.625 2.08579 12.625 2.5C12.625 2.91421 12.2892 3.25 11.875 3.25H4.375C4.09886 3.25 3.875 3.47386 3.875 3.75V13.75C3.875 14.1642 3.53921 14.5 3.125 14.5C2.71079 14.5 2.375 14.1642 2.375 13.75V3.75Z'
        fill={color}
      />
    </svg>
  )
}
export const EditIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M10.2186 1.96287C10.0053 1.76474 9.72264 1.65862 9.43171 1.66751C9.14078 1.6764 8.86508 1.79959 8.66437 2.01038L1.87749 8.79725L1.6671 12.0957C1.66511 12.1274 1.66989 12.1592 1.68112 12.1889C1.69236 12.2186 1.70979 12.2456 1.73225 12.2681C1.75472 12.2905 1.78171 12.308 1.81143 12.3192C1.84115 12.3304 1.87292 12.3352 1.90464 12.3332L5.20306 12.1228L11.9899 5.33595C12.2007 5.13523 12.3239 4.85953 12.3328 4.5686C12.3417 4.27768 12.2356 3.99497 12.0374 3.78175L10.2186 1.96287Z'
        stroke='#402080'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export const BulkUpdateIcon: React.FC<colortype> = ({ color = '#3E3F40' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' fill='none'>
      <path
        d='M17.538 3.84588C17.9755 3.84588 18.3335 4.20384 18.3335 4.64134V16.5732C18.3335 17.4482 17.6176 18.1641 16.7426 18.1641H4.81077C4.37327 18.1641 4.01531 17.8061 4.01531 17.3686C4.01531 16.9311 4.37327 16.5732 4.81077 16.5732H15.9471C16.3846 16.5732 16.7426 16.2152 16.7426 15.7777V4.64134C16.7426 4.20384 17.1005 3.84588 17.538 3.84588ZM2.42441 0.664062H13.5608C14.4358 0.664062 15.1517 1.37997 15.1517 2.25497V13.3913C15.1517 14.2663 14.4358 14.9822 13.5608 14.9822H2.42441C1.5494 14.9822 0.833496 14.2663 0.833496 13.3913V2.25497C0.833496 1.37997 1.5494 0.664062 2.42441 0.664062ZM3.21986 13.3913H12.7653C13.2028 13.3913 13.5608 13.0334 13.5608 12.5959V3.05043C13.5608 2.61293 13.2028 2.25497 12.7653 2.25497H3.21986C2.78236 2.25497 2.42441 2.61293 2.42441 3.05043V12.5959C2.42441 13.0334 2.78236 13.3913 3.21986 13.3913Z'
        fill={color}
      />
      <path
        d='M8.0341 4.10569L5.94686 6.19293C5.70746 6.43233 5.87205 6.83631 6.2087 6.83631H7.54783V10.5769C7.54783 10.9883 7.88448 11.325 8.29594 11.325C8.70741 11.325 9.04406 10.9883 9.04406 10.5769V6.83631H10.3832C10.7198 6.83631 10.8844 6.43233 10.645 6.20041L8.55778 4.11317C8.41564 3.96355 8.17624 3.96355 8.0341 4.10569Z'
        fill={color}
      />
    </svg>
  )
}

export const PartnerIcon: React.FC<colortype> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_24349_40247)'>
        <path
          d='M7.49984 9.9974C9.10817 9.9974 10.4165 8.68906 10.4165 7.08073C10.4165 5.4724 9.10817 4.16406 7.49984 4.16406C5.8915 4.16406 4.58317 5.4724 4.58317 7.08073C4.58317 8.68906 5.8915 9.9974 7.49984 9.9974ZM7.49984 5.83073C8.1915 5.83073 8.74984 6.38906 8.74984 7.08073C8.74984 7.7724 8.1915 8.33073 7.49984 8.33073C6.80817 8.33073 6.24984 7.7724 6.24984 7.08073C6.24984 6.38906 6.80817 5.83073 7.49984 5.83073ZM7.49984 11.4557C5.54984 11.4557 1.6665 12.4307 1.6665 14.3724V14.9974C1.6665 15.4557 2.0415 15.8307 2.49984 15.8307H12.4998C12.9582 15.8307 13.3332 15.4557 13.3332 14.9974V14.3724C13.3332 12.4307 9.44984 11.4557 7.49984 11.4557ZM3.6165 14.1641C4.3165 13.6807 6.00817 13.1224 7.49984 13.1224C8.9915 13.1224 10.6832 13.6807 11.3832 14.1641H3.6165ZM13.3665 11.5057C14.3332 12.2057 14.9998 13.1391 14.9998 14.3724V15.8307H17.4998C17.9582 15.8307 18.3332 15.4557 18.3332 14.9974V14.3724C18.3332 12.6891 15.4165 11.7307 13.3665 11.5057ZM12.4998 9.9974C14.1082 9.9974 15.4165 8.68906 15.4165 7.08073C15.4165 5.4724 14.1082 4.16406 12.4998 4.16406C12.0498 4.16406 11.6332 4.2724 11.2498 4.45573C11.7748 5.1974 12.0832 6.10573 12.0832 7.08073C12.0832 8.05573 11.7748 8.96406 11.2498 9.70573C11.6332 9.88906 12.0498 9.9974 12.4998 9.9974Z'
          fill='#3E3F40'
        />
      </g>
      <defs>
        <clipPath id='clip0_24349_40247'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const PartnerActiveIcon: React.FC<colortype> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clipPath='url(#clip0_25299_43422)'>
        <path
          d='M7.49984 9.9974C9.10817 9.9974 10.4165 8.68906 10.4165 7.08073C10.4165 5.4724 9.10817 4.16406 7.49984 4.16406C5.8915 4.16406 4.58317 5.4724 4.58317 7.08073C4.58317 8.68906 5.8915 9.9974 7.49984 9.9974ZM7.49984 5.83073C8.1915 5.83073 8.74984 6.38906 8.74984 7.08073C8.74984 7.7724 8.1915 8.33073 7.49984 8.33073C6.80817 8.33073 6.24984 7.7724 6.24984 7.08073C6.24984 6.38906 6.80817 5.83073 7.49984 5.83073ZM7.49984 11.4557C5.54984 11.4557 1.6665 12.4307 1.6665 14.3724V14.9974C1.6665 15.4557 2.0415 15.8307 2.49984 15.8307H12.4998C12.9582 15.8307 13.3332 15.4557 13.3332 14.9974V14.3724C13.3332 12.4307 9.44984 11.4557 7.49984 11.4557ZM3.6165 14.1641C4.3165 13.6807 6.00817 13.1224 7.49984 13.1224C8.9915 13.1224 10.6832 13.6807 11.3832 14.1641H3.6165ZM13.3665 11.5057C14.3332 12.2057 14.9998 13.1391 14.9998 14.3724V15.8307H17.4998C17.9582 15.8307 18.3332 15.4557 18.3332 14.9974V14.3724C18.3332 12.6891 15.4165 11.7307 13.3665 11.5057ZM12.4998 9.9974C14.1082 9.9974 15.4165 8.68906 15.4165 7.08073C15.4165 5.4724 14.1082 4.16406 12.4998 4.16406C12.0498 4.16406 11.6332 4.2724 11.2498 4.45573C11.7748 5.1974 12.0832 6.10573 12.0832 7.08073C12.0832 8.05573 11.7748 8.96406 11.2498 9.70573C11.6332 9.88906 12.0498 9.9974 12.4998 9.9974Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_25299_43422'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
