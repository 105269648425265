import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CONSTANTS from '../../Constants'
import { ASSET_CREATION, HOME } from '../../Constants/ApiDefinations'
import { combineErrorMessage, getDateInFormat, setRouteToLocalStorage } from '../../Helpers/Util'
import { TypeObject } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import CircleArrowIcon from '../Icons/CircleArrowIcon'
import RecentActivityHeaderIcon from '../Icons/RecentActivityHeaderIcon'

/**
 * RecentActivity Component
 *
 * Renders Recent Activity Asset Component.
 *
 * @returns {React.JSX.Element | null} JSX Element representing the RecentActivity component.
 */

interface RecentActivityProps {
  title: string
  scheduleStatus: string
  updatedAt: string
  _id: string
  contentId: number
  contentStatus: string
  updatedBy: string
  blitzId: number
}

const RecentActivity: React.FC = () => {
  const [activities, setActivities] = useState<RecentActivityProps[]>([])
  const [loader, setLoader] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    getRecentActivities()
  }, [])

  /**
   * Get the data of recent activities.
   * @function getRecentActivities
   * @returns {void}
   */
  const getRecentActivities = async () => {
    setLoader(true)
    const payload = HOME.RECENT_ACTIVITY
    const result: TypeObject = await mainApiService(payload)
    if (result?.error) {
      dispatch(showAlert([true, combineErrorMessage(result), 'info']))
      setLoader(false)

      return
    }

    if (result?.data?.recentActivity?.length) {
      setActivities(result.data.recentActivity)
    }
    setLoader(false)
  }

  /**
   * Get the status of recent activities.
   * @function getStatus
   * @param {string} state - state of the asset eg. PUBLISHED, RIGHTS_EXPIRED, DE_PUBLISH etc.
   * @returns {string} - Display value of state eg. Published, Rights Expired,  Depublish etc.
   */
  const getStatus = (state: string) => {
    const statusObj: TypeObject = { ...CONSTANTS.ASSET_STATE_DISPLAY }
    return statusObj[state]
  }

  /**
   * Function to fetch the details of asset based on asset id and based that info it will redirect to asset details page.
   * @function redirectToDetailsPage
   * @param {string} id - asset id / content id.
   * @returns {void}
   */
  const redirectToDetailsPage = async (id: string) => {
    const reqPayload = ASSET_CREATION.VIEW_ASSET_REPO(id)
    const result: TypeObject = await mainApiService(reqPayload)
    if (result?.error) {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
      return
    }
    if (result?.data) {
      setRouteToLocalStorage(`${location.pathname}${location.search}`)
      navigate(
        `/asset/view/${result.data._id}/${result.data.assetType}/${result.data.contentCategory}/${result.data.assetCategory}/${CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY}`,
      )
    }
  }

  return (
    <div className='recent-activity-wrapper'>
      {loader ? (
        <div className='loader page'>
          <CircularProgress variant='indeterminate' />
        </div>
      ) : (
        <>
          <h3>
            <RecentActivityHeaderIcon />
            Recent Activity
          </h3>
          <div className='recent-activity-list'>
            {!!activities?.length &&
              activities.map((item: RecentActivityProps) => {
                return (
                  <div
                    className='activity-row '
                    key={item._id}
                    data-testid='activity-row'
                    onClick={() => redirectToDetailsPage(item._id)}
                  >
                    <div className='icon-left'>
                      <CircleArrowIcon />
                    </div>
                    <div className='publised-id pl-1' data-testid='activity-id'>
                      {getStatus(item.contentStatus)}{' '}
                      <span className='paragraph-bold-6'>ID: {item.blitzId} </span>
                    </div>
                    <div className='publised-text pl-1' data-testid='activity-text'>
                      {item.title}{' '}
                    </div>
                    <div className='publised-author pl-1' data-testid='activity-author'>
                      By: {item.updatedBy}{' '}
                      <span>{getDateInFormat(item.updatedAt, CONSTANTS.DATE_FORMAT_TIME)}</span>
                    </div>
                  </div>
                )
              })}

            {!loader && activities?.length === 0 && (
              <div className='d-flex align-items-center justify-content-center mt-5 mb-5 heading-3'>
                No Records Found
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default RecentActivity
