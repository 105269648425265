import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PARTNER_APIS } from '../../../Constants/ApiDefinations'
import { validateField, validateForm } from '../../../Helpers/FormRenderHelper'
import { getAssetTypeKeyValue, getMasterDropdown, getPartnerStatus } from '../../../Helpers/Util'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import FormRender from '../../FormRender'
import { MUIBox, MUIButton } from '../../Shared'
import { PARTNER_CONSTENT } from './PartnersConstent'

interface PartnersFormType {
  partnersData?: any
  id?: string
  listOptions?: any
}
const PartnersForm: React.FC<PartnersFormType> = ({ partnersData, id, listOptions }) => {
  const [partnersFormFields, setPartnersFormFields] = useState<any>(PARTNER_CONSTENT.initialField)
  const [initialField, setinitialField] = useState([])
  const [mount, setmount] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const getDropdownList = async () => {
    const fieldCopy = JSON.parse(JSON.stringify(partnersFormFields))
    const setFieldOptions = fieldCopy.map((ele: any) => {
      if (ele.fieldName === 'allowedMasters') {
        return { ...ele, options: listOptions.masterList }
      } else if (ele.fieldName === 'allowedAssetTypes') {
        return { ...ele, options: listOptions.assetTypeList }
      } else {
        return ele
      }
    })
    setPartnersFormFields(setFieldOptions)
  }

  useEffect(() => {
    setmount(true)
    if (id === 'edit' && partnersData?._id) {
      const updatedFields = partnersFormFields.map((field: any) => {
        if (field.fieldType === 'SELECT' && !field.isMultiple) {
          return { ...field, value: getPartnerStatus(partnersData[field.fieldName]) }
        } else if (field.fieldName === 'allowedMasters') {
          return {
            ...field,
            value: getMasterDropdown(partnersData.allowedMasters || [], listOptions.masterList),
          }
        } else if (field.fieldName === 'allowedAssetTypes') {
          return {
            ...field,
            value: getAssetTypeKeyValue(
              partnersData?.allowedAssetTypes || [],
              listOptions?.assetTypeList,
            ),
          }
        } else {
          return { ...field, value: partnersData[field.fieldName] }
        }
      })
      setPartnersFormFields(updatedFields)
      setinitialField(updatedFields)
    }
    if (id === 'create') {
      const updatedCreateFields = partnersFormFields.map((field: any) => {
        if (field.fieldName === 'contractEndDate') {
          return { ...field, validations: { ...field.validations, futureDate: true } }
        } else {
          return field
        }
      })
      setPartnersFormFields(updatedCreateFields)
    }
  }, [partnersData])
  useEffect(() => {
    if (mount) {
      getDropdownList()
    }
  }, [mount, listOptions])
  const handleChangeInput = (inputValue: any, index: number, field: any) => {
    let inputValueCopy = inputValue
    if (field.fieldName === 'serviceAlias') {
      inputValueCopy = String(inputValueCopy).toUpperCase()
      const inputElement = document.getElementById(field.fieldName) as HTMLInputElement
      if (inputElement) {
        const { selectionStart, selectionEnd } = inputElement
        const newValue = inputValueCopy.toUpperCase()
        inputElement.value = newValue
        inputElement.setSelectionRange(selectionStart, selectionEnd)
      }
    }
    const partnersFormFieldsCopy = JSON.parse(JSON.stringify(partnersFormFields))
    const { value, isValid, errorMessage } = validateField(
      partnersFormFieldsCopy[index],
      inputValueCopy,
      false,
      partnersFormFieldsCopy,
    )
    partnersFormFieldsCopy[index] = {
      ...partnersFormFieldsCopy[index],
      value,
      isValid,
      errorMessage,
    }
    if (field?.dependencyIndex) {
      const conpareField = validateField(
        partnersFormFieldsCopy[field?.dependencyIndex],
        partnersFormFieldsCopy[field?.dependencyIndex]?.value,
        false,
        partnersFormFieldsCopy,
      )
      partnersFormFieldsCopy[field?.dependencyIndex] = {
        ...partnersFormFieldsCopy[field?.dependencyIndex],
        isValid: conpareField.isValid,
        errorMessage: conpareField.errorMessage,
      }
    }
    setPartnersFormFields(partnersFormFieldsCopy)
  }

  const updatePartnerFunction = async () => {
    const payload = partnersFormFields.reduce(
      (acc: any, field: any) => {
        acc[field?.fieldName] =
          field.fieldType === 'SELECT' && !field.isMultiple
            ? field.value.key
            : field.fieldType === 'SELECT' && field.isMultiple
            ? field.value.map((ele: any) => ele.value)
            : field.value
        return acc
      },
      { partnerId: partnersData?._id, resendEmail: false },
    )

    if (payload.allowedAssetTypes.length || payload.allowedMasters.length) {
      if (payload.contactPhone === '') {
        payload.contactPhone = null
      }
      dispatch(setLoader(true))
      const requestedData: any = PARTNER_APIS.EDIT_PARTNER(payload)
      const result = await mainApiService(requestedData)
      dispatch(setLoader(false))
      if (result?.responseCode === 200) {
        dispatch(showAlert([true, result?.message, 'success']))
        setPartnersFormFields(PARTNER_CONSTENT.initialField)
        navigate(`/partners/view/${partnersData?._id}`)
      } else {
        dispatch(showAlert([true, result?.error?.errors || result?.error?.message, 'error']))
      }
    } else {
      dispatch(showAlert([true, PARTNER_CONSTENT.errorMessageForAllowed, 'error']))
    }
  }
  const createPartnerFunction = async () => {
    const payload = partnersFormFields.reduce((acc: any, field: any) => {
      acc[field?.fieldName] =
        field.fieldType === 'SELECT' && !field.isMultiple
          ? field.value.key
          : field.fieldType === 'SELECT' && field.isMultiple
          ? field.value.map((ele: any) => ele.value)
          : field.value
      return acc
    }, {})
    if (payload.contactPhone === '') {
      payload.contactPhone = null
    }
    if (payload.allowedAssetTypes.length || payload.allowedMasters.length) {
      dispatch(setLoader(true))
      const requestedData: any = PARTNER_APIS.CREATE_PARTNER(payload)
      const result = await mainApiService(requestedData)
      dispatch(setLoader(false))

      if (result?.responseCode === 200) {
        dispatch(showAlert([true, result.message, 'success']))
        setPartnersFormFields(PARTNER_CONSTENT.initialField)
        navigate('/partners')
      } else {
        dispatch(showAlert([true, result?.error?.errors || result?.error?.message, 'error']))
      }
    } else {
      dispatch(showAlert([true, PARTNER_CONSTENT.errorMessageForAllowed, 'error']))
    }
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const { isValid, formConfig } = validateForm(partnersFormFields, true)
    if (isValid) {
      if (id === 'create') {
        createPartnerFunction()
      } else {
        updatePartnerFunction()
      }
    } else {
      setPartnersFormFields(formConfig)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <MUIBox>
        <div>
          <div className='heading-6 d-flex text-left rolebox__header-padding'>
            Partner Information
          </div>
          <div className={'hr'}></div>
          <div className='rolebox__content-padding'>
            <Grid container columnSpacing={10} rowSpacing={4}>
              <FormRender
                formFields={partnersFormFields}
                onChange={handleChangeInput}
                mount={mount}
              />

              <Grid item xs={12} className='d-flex justify-content-end'>
                <MUIButton
                  size='large'
                  type={'submit'}
                  label={'Submit'}
                  variant='contained'
                  width={200}
                  className='c-btn'
                  disabled={
                    id === 'edit' &&
                    JSON.stringify(initialField) === JSON.stringify(partnersFormFields)
                  }
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </MUIBox>
    </form>
  )
}

export default PartnersForm
