import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS } from '../../../Constants'
import {
  ASSET_TYPE_DEFINITION_CRUD,
  GLOBAL_SEARCH,
  PARTNER_APIS,
} from '../../../Constants/ApiDefinations'
import { TIPS_MODULE } from '../../../Helpers/ManageTips'
import { getPageTitle, removeDuplicates } from '../../../Helpers/Util'
import updateTagMetaData from '../../../Helpers/updateTagMetaData'
import { BreadcrumsModel } from '../../../Models'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { BackButton, Breadcrumbs, MUITipsCard } from '../../Shared'
import PartnersForm from './PartnersForm'
import PartnersInformation from './PartnersInformation'

const PartnersEditor: React.FC<{
  id: string | undefined
  PartnersId: string | undefined
}> = ({ id, PartnersId }: { id: string | undefined; PartnersId: string | undefined }) => {
  const { VIEW, EDIT, CREATE } = CONSTANTS.PAGE_TYPE
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [partnerData, setpartnerData] = useState<any>({})
  const [viewData, setviewData] = useState<any>([])
  const [listOptions, setListOptions] = useState({ masterList: [], assetTypeList: [] })
  useEffect(() => {
    getListOption()
  }, [])

  const getPartnerInformation = async () => {
    if (id !== CREATE && (id || PartnersId)) {
      const _id = PartnersId || id
      dispatch(setLoader(true))
      const requestedData: any = PARTNER_APIS.VIEW_PARTNER(_id as any)
      const result = await mainApiService(requestedData)
      dispatch(setLoader(false))
      if (!result?.data?._id?.length) {
        dispatch(showAlert([true, result.message, 'error']))
        navigate('/partners')
      } else {
        const viewRes = result.data
        setpartnerData(viewRes)
        setviewData([
          { label: 'Partner Name', value: result?.data?.serviceName, isTitle: true },
          { label: 'Partner Alias', value: result?.data?.serviceAlias, isTitle: false },
          { label: 'Email ID', value: result?.data?.contactEmail, isTitle: false },
          { label: 'Content Number', value: result?.data?.contactPhone, isTitle: false },
          { label: 'Status', value: result?.data?.status, isTitle: false },
          {
            label: 'Contract Start Date',
            value: result?.data.contractStartDate
              ? format(new Date(result?.data.contractStartDate), CONSTANTS.DATE_FORMAT_TIME)
              : 'NA',
            isTitle: false,
          },
          {
            label: 'Contract End Date',
            value: result?.data.contractEndDate
              ? format(new Date(result?.data.contractEndDate), CONSTANTS.DATE_FORMAT_TIME)
              : 'NA',
            isTitle: false,
          },
          {
            label: 'Allowed Asset Type',
            value: result?.data?.allowedAssetTypes?.length
              ? result?.data?.allowedAssetTypes?.map((item: any, ind: number) =>
                  result?.data?.allowedAssetTypes?.length - 1 === ind ? item : `${item}, `,
                )
              : 'NA',
          },
          {
            label: 'Allowed Masters',
            value: result?.data?.allowedMasters?.length
              ? result?.data?.allowedMasters.map((item: any, ind: number) =>
                  result?.data?.allowedMasters?.length - 1 === ind ? item : `${item}, `,
                )
              : 'NA',
          },
        ])
      }
    }
  }
  useEffect(() => {
    if (id === CREATE) {
      updateTagMetaData({ title: 'Create Partner' })
    } else if (partnerData?.serviceName) {
      updateTagMetaData({ title: `Partner: ${partnerData?.serviceName}` })
    }
    return () => {
      updateTagMetaData({})
    }
  }, [partnerData?.serviceName, id])
  useEffect(() => {
    getPartnerInformation()
  }, [id, partnerData?.serviceName, listOptions.masterList])

  const getListOption = async () => {
    const requestedData: any = ASSET_TYPE_DEFINITION_CRUD.LIST_DATA_SOURCE()
    const reqData = GLOBAL_SEARCH.ASSET_DEFINITION_LIST()
    dispatch(setLoader(true))
    const allListResult = await Promise.all([
      await mainApiService(requestedData),
      await mainApiService(reqData),
    ])
    dispatch(setLoader(false))
    if (allListResult) {
      const MasterList = allListResult[0]?.data?.map((ele: any) => {
        const keySplit = ele.key.split('/')
        return {
          ...ele,
          value: keySplit?.length > 1 ? keySplit[1] : keySplit[0],
          masterKey: ele.value,
        }
      })
      const parsedData = allListResult[1]?.data?.map(
        (item: { value: string; assetType: string }) => ({
          ...item,
          value: item.assetType,
        }),
      )
      const uniqueAssetType: any = removeDuplicates(parsedData, 'assetType')
      setListOptions({ masterList: MasterList, assetTypeList: uniqueAssetType })
    }
  }
  return (
    <>
      <div className={`role__editor p-16 ${id !== VIEW ? 'pb-120' : ''}`}>
        <div className='p-8'>
          <Breadcrumbs
            breadcrums={
              BREADCRUMS.CREATE_PARTNER(id, getPageTitle(id as string)) as BreadcrumsModel[]
            }
          />
        </div>
        <div>
          <div className='p-8'>
            <BackButton
              label={`${getPageTitle(id as string)} Partner`}
              className='col73'
              handleOnClick={(e: any) => {
                e.preventDefault()
                navigate('/partners')
              }}
            />
          </div>
          <div className='mt-1'>
            <div className='role__editor-wrapper'>
              <div className='role__editor-content p-8'>
                {id === VIEW ? (
                  <div className=''>
                    <PartnersInformation
                      view={partnerData}
                      viewData={viewData}
                      listOptions={listOptions}
                      getPartnerInformation={getPartnerInformation}
                    />
                  </div>
                ) : (
                  <PartnersForm partnersData={partnerData} id={id} listOptions={listOptions} />
                )}
              </div>
              <div className=' p-8  ml-1 '>
                <div>
                  <MUITipsCard
                    tipsArr={CONSTANTS.PARTNER_TIPS}
                    module={TIPS_MODULE.MANAGE_PARTNER}
                    pageType={[VIEW, CREATE].includes(id as string) ? id : EDIT}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PartnersEditor
